import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';

import { AccountService } from '../../../_services/account.service';
import { AuthenticationService } from '../../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../../core/services/authfake.service';

import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

/**
 * Login component
 */
export class LoginComponent implements OnInit, AfterViewInit {

  loginForm: UntypedFormGroup;
  submitted = false;
  loading = false;
  error = '';
  returnUrl: string;

  // set the currenr year
  year: number = new Date().getFullYear();


  // tslint:disable-next-line: max-line-length
  constructor(
      private formBuilder: UntypedFormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private authenticationService: AuthenticationService,
      private authFackservice: AuthfakeauthenticationService,
      private accountService: AccountService) { }

  ngOnInit() {
    this.loading =false
    document.body.setAttribute('class', 'authentication-bg');

    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });

    // reset login status
    
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  ngAfterViewInit() {
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }


  onSubmit() {
    this.loading = false;
    document.body.setAttribute('class', 'authentication-bg');
      this.submitted = true;

      this.accountService.login(this.f.email.value, this.f.password.value)
          .pipe(first())
          .subscribe(
            data => {
              document.body.removeAttribute('class');
              this.router.navigate(['/dashboard']);
            },
            error => {
              this.error = error ? error : '';
            });
  }
}
