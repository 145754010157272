import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from '../../../_services/account.service';
import { AuthenticationService } from '../../../core/services/auth.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-passwordreset',
  templateUrl: './passwordreset.component.html',
  styleUrls: ['./passwordreset.component.scss']
})

/**
 * Reset-password component
 */
export class PasswordresetComponent implements OnInit, AfterViewInit {

  resetForm: UntypedFormGroup;
  mobile : string;
  submitted = false;
  error = '';
  success = '';
  loading = false;
  year: number = new Date().getFullYear();
  constructor(
    
    private formBuilder: UntypedFormBuilder, 
    private route: ActivatedRoute, 
    private router: Router,
    private authenticationService: AuthenticationService,
    private accountService: AccountService
    ) { }

  ngOnInit() {
    this.mobile = this.route.snapshot.params['mobile'];
    this.resetForm = this.formBuilder.group({
      mobile: ['', Validators.required],
    });
  }
  ngAfterViewInit() {
  }
  // convenience getter for easy access to form fields
  get f() { return this.resetForm.controls; }

  onSubmit() {
    document.body.setAttribute('class', 'authentication-bg');
      this.submitted = true;

      this.accountService.forgotPassword(this.f.mobile.value)
          .subscribe(
            data => {
              document.body.removeAttribute('class');
              this.router.navigate(['/account/reset-password/'+this.f.mobile.value]);
            },
            error => {
              this.error = error ? error : '';
            });
  }
}
