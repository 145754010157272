import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { Account,Role,Module,Page,Permission } from '../_models';
import { aC } from '@fullcalendar/core/internal-common';

const baseUrl = `${environment.apiUrl}/accounts`;
const roleUrl = `${environment.apiUrl}/role`;
const moduleUrl = `${environment.apiUrl}/module`;
const pageUrl = `${environment.apiUrl}/page`;
const permissionUrl = `${environment.apiUrl}/permission`;

@Injectable({ providedIn: 'root' })
export class AccountService {
    private accountSubject: BehaviorSubject<Account>;
    public account: Observable<Account>;
    private roleSubject: BehaviorSubject<Role>;
    public role: Observable<Role>;
    private moduleSubject: BehaviorSubject<Module>;
    public module: Observable<Module>;
    private pageSubject: BehaviorSubject<Page>;
    public page: Observable<Page>;
    private permissionSubject: BehaviorSubject<Permission>;
    public permission: Observable<Permission>;


    constructor(
        private router: Router,
        private http: HttpClient

        
    ) {
        
        this.accountSubject = new BehaviorSubject<Account>(null);
        this.account = this.accountSubject.asObservable();
        this.roleSubject = new BehaviorSubject<Role>(null);
        this.role = this.roleSubject.asObservable();
        this.moduleSubject = new BehaviorSubject<Module>(null);
        this.module = this.moduleSubject.asObservable();
        this.pageSubject = new BehaviorSubject<Page>(null);
        this.page = this.pageSubject.asObservable();
        this.permissionSubject = new BehaviorSubject<Permission>(null);
        this.permission = this.permissionSubject.asObservable();
    }

  

    public get accountValue(): Account {
        return  JSON.parse(localStorage.getItem('account'));
    }
    public get roleValue(): Role {
        return this.roleSubject.value;
    }
    public get moduleValue(): Module {
        return this.moduleSubject.value;
    }
    public get pageValue(): Page {
        return this.pageSubject.value;
    }
    public get permissionValue(): Permission {
        return this.permissionSubject.value;
    }
    
    logout() {;
        const token = localStorage.getItem('token')
        this.http.post<any>(`${baseUrl}/revoke-token/${token}`, { }).subscribe();
        this.stopRefreshTokenTimer();
        this.accountSubject.next(null);
        localStorage.removeItem('token');
        localStorage.removeItem('account');
        this.router.navigate(['/account/login']);
    }
    register(account: Account) {
        return this.http.post(`${baseUrl}/register`, account);
    }
    activate(key: string) {
        return this.http.get(`${baseUrl}/activate/${key}`);
    }
    forgotPassword(mobile: string) {
        return this.http.post(`${baseUrl}/forgot-password`, { mobile });
    }
    validateResetToken(token: string) {
        return this.http.post(`${baseUrl}/validate-reset-token`, { token });
    }
    
    resetPassword(token: string, password: string, confirmPassword: string) {
        return this.http.post(`${baseUrl}/reset-password`, { token, password, confirmPassword });
    }
    changePassword(params) {
        return this.http.post(`${baseUrl}/change-password`, params);
    }

    getAccountStats() {
        return this.http.get<Account[]>(`${baseUrl}/account/status`);
    }
    getAllAccounts(districtId : string, branchId : string,blocked : string,active  :string,supervision : string,name : string,email : string,mobile : string,pending : string) {
        return this.http.get<Account[]>(`${baseUrl}/getall/${districtId}/${branchId}/${blocked}/${active}/${supervision}/${name}/${email}/${mobile}/${pending}`);
    }
    getTeamMember(id: string) {
        return this.http.get<Account[]>(`${baseUrl}/team/${id}`);
    }
    getAllBranchMember(id: string) {
        return this.http.get<Account[]>(`${baseUrl}/branch/${id}`);
    }
    getAllMember(id: string) {
        return this.http.get<Account[]>(`${baseUrl}/department/${id}`);
    }
    getBranchUser(id: string) {
        return this.http.get<Account[]>(`${baseUrl}/branch/${id}`);
    }
    getAllRole() {
        return this.http.get<Role[]>(roleUrl);
    }
    getAllModule() {
        return this.http.get<Module[]>(moduleUrl);
    }
    getAllPage() {
        return this.http.get<Page[]>(pageUrl);
    }
    getAllPermission() {
        return this.http.get<Permission[]>(permissionUrl);
    }
    getById(id: string) {
        return this.http.get<Account>(`${baseUrl}/${id}`);
    }
    getByEmail(id: string) {
        return this.http.get<Account>(`${baseUrl}/email/${id}`);
    }
    getRoleById(id: string) {
        return this.http.get<Role>(`${roleUrl}/${id}`);
    }
    getModuleById(id: string) {
        return this.http.get<Module>(`${moduleUrl}/${id}`);
    }
    getPageById(id: string) {
        return this.http.get<Page[]>(`${pageUrl}/${id}`);
    }
    getModulePage(id: string) {
        return this.http.get<Page[]>(`${pageUrl}/module/${id}`);
    }
    getPermissionById(id: string) {
        return this.http.get<Permission[]>(`${permissionUrl}/${id}`);
    }
    getRolePermissionById(id: string) {
        return this.http.get<Permission[]>(`${permissionUrl}/role/${id}`);
    }
    getModulePermission(id: string) {
        return this.http.get<Permission[]>(`${permissionUrl}/module/${id}`);
    }
    getModuleWithPermission(id: string) {
        return this.http.get<Permission[]>(`${permissionUrl}/module/permission/${id}`);
    }
    getPagePermission(moduleId: string,roleId: string) {
        return this.http.get<Permission[]>(`${permissionUrl}/page/${moduleId}/${roleId}`);
    }
    getAccess(pageId: string,roleId: string) {
        return this.http.get<Permission>(`${permissionUrl}/access/${pageId}/${roleId}`);
    }
    getToken(accountId: string) {
        return this.http.get<Permission>(`${baseUrl}/getToken/${accountId}`);
        //  localStorage.setItem(this.tokenKey, token);
    }
    create(params) {
        return this.http.post(baseUrl, params);
    }
    
    update(id, params) {
        return this.http.put(`${baseUrl}/${id}`, params)
            .pipe(map((account: any) => {
                // update the current account if it was updated
                if (account.id === this.accountValue.id) {
                    // publish updated account to subscribers
                    account = { ...this.accountValue, ...account };
                    this.accountSubject.next(account);
                }
                return account;
            }));
    }

    
    delete(id: string) {
        return this.http.delete(`${baseUrl}/${id}`)
            .pipe(finalize(() => {
                // auto logout if the logged in account was deleted
                if (id === this.accountValue.id)
                    this.logout();
            }));
    }
    markAdmin(id,teamId,departmentId, params) {
        return this.http.put(`${baseUrl}/team/${id}/${teamId}/${departmentId}`, params)
            .pipe(map((account: any) => {
                    account = { ...this.accountValue, ...account };
                    this.accountSubject.next(account);
           
                return account;
            }));
    }
    createRole(params) {
        return this.http.post(roleUrl, params);
    }
    
   
    deleteRole(id: string) {
        return this.http.delete(`${roleUrl}/${id}`)
            .pipe(finalize(() => {
               
            }));
    }
    createModule(params) {
        return this.http.post(moduleUrl, params);
    }
    
    updateModule(id, params) {
        return this.http.put(`${moduleUrl}/${id}`, params)
            .pipe(map((module: any) => {
                    module = { ...this.moduleValue, ...module };
                    this.moduleSubject.next(module);
                return module;
            }));
    }
    
    deleteModule(id: string) {
        return this.http.delete(`${moduleUrl}/${id}`)
            .pipe(finalize(() => {
                // auto logout if the logged in account was deleted
                if (id === this.moduleValue.id)
                    this.logout();
            }));
    }
    createPage(params) {
        return this.http.post(pageUrl, params);
    }
    updatePage(id, params) {
        return this.http.put(`${pageUrl}/${id}`, params)
            .pipe(map((page: any) => {
                    page = { ...this.pageValue, ...page };
                    this.pageSubject.next(page);
                return page;
            }));
    }
    deletePage(id: string) {
        return this.http.delete(`${pageUrl}/${id}`)
            .pipe(finalize(() => {
                if (id === this.pageValue.id)
                    this.logout();
            }));
    }
    createPermission(params) {
        return this.http.post(permissionUrl, params);
    }
    updatePermission(id, params) {
        return this.http.put(`${permissionUrl}/${id}`, params)
            .pipe(map((permission: any) => {
                    permission = { ...this.permissionValue, ...permission };
                    this.permissionSubject.next(permission);
                return permission;
            }));
    }
    deletePermission(id: string) {
        return this.http.delete(`${permissionUrl}/${id}`)
            .pipe(finalize(() => {
                if (id === this.permissionValue.id)
                    this.logout();
            }));
    }
    // helper methods

    login(email: string, password: string) {
        return this.http.post<any>(`${baseUrl}/authenticate`, { email, password }, { withCredentials: true })
            .pipe(map(account => {
               
                
                let a = JSON.stringify(account);
                localStorage.setItem('account', a);
                localStorage.setItem('token', account?.jwtToken);
                this.accountSubject.next(account);
                this.startRefreshTokenTimer();
                return account;
            }));
    }
    refreshToken() {
        return this.http.post<any>(`${baseUrl}/refresh-token`, {}, { withCredentials: true })
            .pipe(map((account) => {
                localStorage.setItem('account', account);
                localStorage.setItem('token', account?.jwtToken);
                this.accountSubject.next(account);
                this.startRefreshTokenTimer();
                return account;
            }));
    }

    private refreshTokenTimeout;

    private startRefreshTokenTimer() {
        // parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(atob(this.accountValue.jwtToken.split('.')[1]));

        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 10000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }
}