<div class="account-pages mt-5 mb-5" >
  <div class="container" >
    <div class="row">
      <div class="col-lg-12">
        <div class="text-center">
          <a href="/dashboard" class="mb-5 d-block auth-logo">
            <img src="assets/img/goh2.jpg" alt="" height="240" class="logo logo-dark">
            <img src="assets/img/goh.jpg" alt="" height="190" class="logo logo-light">
          </a>
        </div>
      </div>
    </div>
    <div class="row align-items-center justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div class="card overflow-hidden">

          <div class="card-body p-4">
            <div class="text-center mt-2">
              <h5 class="text-primary">Welcome Back !</h5>
              <p class="text-muted">Sign in to continue to OneWallet.</p>
            </div>
            <div class="p-2 mt-4">
              <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">

                <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>

                <div class="mb-3">
                  <label for="email">Username</label>
                  <input type="email" formControlName="email" class="form-control" id="email" placeholder="Email"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                  <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                    <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                  </div>
                </div>

                <div class="mb-3">
                  <div class="float-end">
                    <a href="/account/reset-password" class="text-muted">Forgot password?</a>
                  </div>
                  <label for="password">Password</label>
                  <input type="password" formControlName="password" class="form-control" id="password"
                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="Password" />
                  <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">Password is required</div>
                  </div>
                </div>


                <div class="mt-3 text-end">
                  <button class="btn btn-primary w-sm waves-effect waves-light" type="submit">Log In</button>
                </div>
             
              </form>
            </div>

          </div>

        </div>

        <div class="mt-5 text-center">
          <p>© {{year}} OneWallet   powered by OneTap</p>
        </div>

      </div>
    </div>
  </div>

  <!-- end container -->
</div>
<!-- end page -->