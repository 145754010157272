<div class="account-pages my-5 pt-sm-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <div>

          <div class="row">
            <div class="col-lg-12">
              <div class="text-center">
                <a href="/dashboard" class="mb-5 d-block auth-logo">
                  <img src="assets/img/goh2.jpg" alt="" height="240" class="logo logo-dark">
                  <img src="assets/img/goh.jpg" alt="" height="190" class="logo logo-light">
                </a>
              </div>
            </div>
          </div>
          <div class="card">

            <div class="card-body p-4">

              <div class="text-center mt-2">
                <h5 class="text-primary">Reset Password</h5>
                <p class="text-muted">Reset Password.</p>
              </div>
              <div class="p-2 mt-4">
                <div class="alert alert-warning text-center mb-4" role="alert" *ngIf="!mobile">
                  Enter your Mobile and instructions will be sent to you!
                </div>
                <div class="alert alert-success text-center mb-4" role="alert" *ngIf="mobile">
                  Dear user,The password request instruction has been forwarded to the responsible department. Once your request is approved, you will receive a fresh SMS on your mobile number {{mobile}}. 
                </div>
                <form class="needs-validation" name="resetForm" [formGroup]="resetForm" (ngSubmit)="onSubmit()"
                  novalidate>
                  <ngb-alert type="danger" *ngIf="error" [dismissible]="false">{{ error }}</ngb-alert>

                  <div class="mb-3">
                    <label for="mobile">Mobile</label>
                    <input type="text" formControlName="mobile" class="form-control"
                      [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" id="mobile" placeholder="Mobile" />

                    <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                      <div *ngIf="f.mobile.errors.required">Mobile is required</div>
                      <div *ngIf="f.mobile.errors.mobile">Mobile must be a valid Mobile number</div>
                    </div>
                  </div>

                  <div class="mt-3 text-end">
                    <button class="btn btn-primary w-sm waves-effect waves-light" type="submit">Reset</button>
                  </div>

                  <div class="mt-4 text-center">
                    <p class="mb-0">Remember It ? <a href="/account/login" class="fw-medium text-primary">
                        Signin </a></p>
                  </div>
                </form>
              </div>

            </div>
          </div>
          <div class="mt-5 text-center">
            <p>© {{year}} OneWallet   powered by OneTap</p>
          </div>
        </div>
      </div> <!-- end col -->
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</div>
<!-- end page -->