import { Component, OnInit } from '@angular/core';
import { AccountService } from '../app/_services/account.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  modules: any[];
  account = this.accountService.accountValue;
  constructor(private accountService: AccountService) {}
  ngOnInit() {
    //  document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");  

    
    // this.accountService.getModulePermission(this.account.roleId)
    // .subscribe(modules => this.modules = modules);
  }

}
