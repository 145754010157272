import { MenuItem } from './menu.model';


export const MENU: MenuItem[] = [
 
    {
        id: 2,
        label: 'MENUITEMS.USERTOTAL.TEXT',
        value: 'UserTotal',
        icon: 'fa-clock',
        badge: 'UserTotal',
        link: '/totalregistration',
    },{
        id: 3,
        label: 'MENUITEMS.USERDAILY.TEXT',
        value: 'Dashboard',
        icon: 'fa-clock',
        badge: 'Dashboard',
        link: '/dashboard',
    },{
        id: 4,
        label: 'MENUITEMS.TRANSACTIONDAILY.TEXT',
        value: 'TransactionDaily',
        icon: 'fa-home',
        badge: 'TransactionDaily',
        link: '/transactiondashboard',
    },
    {
        id: 5,
        label: 'MENUITEMS.TRANSACTIONDASHBOARDS.TEXT',
        value: 'TransactionDashboard',
        badge: 'TransactionDashboard',
        icon: 'fa-chart-area',
       
        link: '/totaltransactiondashboard',
    },  {
        id: 6,
        label: 'MENUITEMS.RECENTACTIVITY.TEXT',
        value: 'RecentActivity',
        badge: 'RecentActivity',
        icon: ' fa-tasks',
        link: '/recentactivity',
    },{
        id: 8,
        label: 'MENUITEMS.USSDCUSTOMERS.TEXT',
        value: 'USSDCustomers',
        badge: 'USSDCustomers',
        icon: 'fa-network-wired',
        subItems: [
            {
                id: 81,
                label: 'MENUITEMS.USSDCUSTOMERS.LIST.SEARCH',
                icon: 'fa-search',
                v: 'SearchCustomer',
                badge: 'USSDCustomers',
                link: '/ussdcustomers/search',
                parentId: 8
            },{
                id: 82,
                label: 'MENUITEMS.USSDCUSTOMERS.LIST.PENDING',
                icon: 'fa-check-square',
                v: 'PendingApproval',
                badge: 'USSDCustomers',
                link: '/ussdcustomers/pending',
                parentId: 8
            },{
                id: 112,
                label: 'MENUITEMS.USSDCUSTOMERS.LIST.CHANGE',
                icon: 'fa-edit',
                v: 'ChangeMobile',
                badge: 'USSDCustomers',
                link: '/ussdcustomers/change',
                parentId: 24
            },{
                id: 153,
                label: 'MENUITEMS.USSDSETTING.LIST.CardManagements',
                icon: 'fa-credit-card',
                v: 'BranchCardManagements',
                badge: 'USSDCustomers',
                link: '/ussdcustomers/cardmanagement',
                parentId: 15
            },{
                id: 153,
                label: 'MENUITEMS.USSDSETTING.LIST.ChequeManagements',
                icon: 'fa-sticky-note',
                v: 'BranchChequeManagements',
                badge: 'USSDCustomers',
                link: '/ussdcustomers/chequemanagement',
                parentId: 15
            }
        ]
    }, {
        id: 12,
        label: 'MENUITEMS.TRANSACTIONANALYTICS.TEXT',
        value: 'TransactionAnalytics',
        badge: 'TransactionAnalytics',
        icon: 'fa-chart-line',
        subItems: [
            {
                id: 121,
                label: 'MENUITEMS.TRANSACTIONANALYTICS.LIST.BRANCH',
                link: '/transactionanalytics/branch',
                badge: 'TransactionAnalytics',
                v: 'BranchesTransactionReport',
                icon: 'fa-chart-pie',
                parentId: 12
            },
            {
                id: 122,
                label: 'MENUITEMS.TRANSACTIONANALYTICS.LIST.DISTRICT',
                link: '/transactionanalytics/district',
                badge: 'TransactionAnalytics',
                v: 'DistrictsTransactionsReport',
                icon: 'fa-chart-pie',
                parentId: 12
            },
            {
                id: 123,
                label: 'MENUITEMS.TRANSACTIONANALYTICS.LIST.DETAIL',
                link: '/transactionanalytics/detail',
                badge: 'TransactionAnalytics',
                v: 'NumberofTransactionAnalytics',
                icon: 'fa-chart-pie',
                parentId: 12
            },
            {
                id: 124,
                label: 'MENUITEMS.TRANSACTIONANALYTICS.LIST.DAILY',
                link: '/transactionanalytics/daily',
                badge: 'TransactionAnalytics',
                v: 'DailyAnalytics',
                icon: 'fa-chart-pie',
                parentId: 12
            }, {
                id: 125,
                label: 'MENUITEMS.TRANSACTIONANALYTICS.LIST.WEEKLY',
                link: '/transactionanalytics/weekly',
                badge: 'TransactionAnalytics',
                v: 'WeeklyAnalytics',
                icon: 'fa-chart-pie',
                parentId: 12
            }, {
                id: 126,
                label: 'MENUITEMS.TRANSACTIONANALYTICS.LIST.MONTHLY',
                link: '/transactionanalytics/monthly',
                badge: 'TransactionAnalytics',
                v: 'MonthlyAnalytics',
                icon: 'fa-chart-pie',
                parentId: 12
            }, {
                id: 127,
                label: 'MENUITEMS.TRANSACTIONANALYTICS.LIST.YEARLY',
                link: '/transactionanalytics/yearly',
                badge: 'TransactionAnalytics',
                v: 'YearlyAnalytics',
                icon: 'fa-chart-pie',
                parentId: 12
            }
        ]
    }, {
        id: 14,
        label: 'MENUITEMS.USERANALYTICS.TEXT',
        value: 'UserAnalytics',
        badge: 'UserAnalytics',
        icon: 'fa-chart-bar',
        subItems: [{
            id: 141,
            label: 'MENUITEMS.USERANALYTICS.LIST.DETAIL',
            link: '/useranalytics/detail',
            v: 'DetailRegistrationAnalytics',
            badge: 'UserAnalytics',
            icon: 'fa-chart-pie',
            parentId: 14
            },
            {
                id: 141,
                label: 'MENUITEMS.USERANALYTICS.LIST.BRANCH',
                link: '/useranalytics/branch',
                v: 'BranchReport',
                badge: 'UserAnalytics',
                icon: 'fa-chart-pie',
                parentId: 14
            },
            {
                id: 142,
                label: 'MENUITEMS.USERANALYTICS.LIST.DISTRICT',
                link: '/useranalytics/district',
                v: 'DistrictsReport',
                badge: 'UserAnalytics',
                icon: 'fa-chart-pie',
                parentId: 14
            }, {
                id: 143,
                label: 'MENUITEMS.USERANALYTICS.LIST.DAILY',
                link: '/useranalytics/daily',
                badge: 'UserAnalytics',
                v: 'DailyRegistrationAnalytics',
                icon: 'fa-chart-pie',
                parentId: 14
            }, {
                id: 144,
                label: 'MENUITEMS.USERANALYTICS.LIST.MONTHLY',
                link: '/useranalytics/monthly',
                badge: 'UserAnalytics',
                v: 'MonthlyRegistrationAnalytics',
                icon: 'fa-chart-pie',
                parentId: 14
            }, {
                id: 145,
                label: 'MENUITEMS.USERANALYTICS.LIST.YEARLY',
                link: '/useranalytics/yearly',
                badge: 'UserAnalytics',
                v: 'YearlyRegistrationAnalytics',
                icon: 'fa-chart-pie',
                parentId: 14
            }, {
                id: 145,
                label: 'MENUITEMS.USERANALYTICS.LIST.AUDIT',
                link: '/useranalytics/audit',
                badge: 'UserAnalytics',
                v: 'CustomerAccountAuditReport',
                icon: 'fa-chart-pie',
                parentId: 14
            }
        ]
    },{
        id: 15,
        label: 'MENUITEMS.USSDSETTINGS.TEXT',
        value: 'USSDSettings',
        badge: 'USSDSettings',
        icon: 'fa-wallet',
        subItems: [
            {
                id: 151,
                label: 'MENUITEMS.USSDSETTING.LIST.BANKS',
                link: '/settings/banks',
                v: 'Banks',
                badge: 'USSDSettings',
                icon: 'fa-comments-dollar',
                parentId: 15
            }
            ,{
                id: 152,
                label: 'MENUITEMS.USSDSETTING.LIST.ACCOUNTSETTING',
                icon: ' fa-user-cog',
                v: 'AccountSetting',
                badge: 'USSDSettings',
                link: '/settings/accountsetting',
                parentId: 15
            },{
                id: 153,
                label: 'MENUITEMS.USSDSETTING.LIST.TRANSACTIONSETTING',
                icon: 'fa-exchange-alt',
                v: 'TransactionLimit',
                badge: 'USSDSettings',
                link: '/settings/transactionlimit',
                parentId: 15
            },{
                id: 153,
                label: 'MENUITEMS.USSDSETTING.LIST.CardManagements',
                icon: 'fa-credit-card',
                v: 'CardManagements',
                badge: 'USSDSettings',
                link: '/settings/CardManagements',
                parentId: 15
            },{
                id: 153,
                label: 'MENUITEMS.USSDSETTING.LIST.ChequeManagements',
                icon: 'fa-sticky-note',
                v: 'ChequeManagements',
                badge: 'USSDSettings',
                link: '/settings/ChequeManagements',
                parentId: 15
            },
            {
                id: 174,
                label: 'MENUITEMS.USSDSETTING.LIST.REQUESTPASSWORD',
                link: '/settings/account/resetpassword',
                v: 'ForgetPassword',
                badge: 'USSDSettings',
                icon: 'fa-key',
                parentId: 17
            }
        ]
    },{
        id: 17,
        label: 'MENUITEMS.SETTING.TEXT',
        value: 'Settings',
        badge: 'Settings',
        icon: 'fa-tools',
        subItems: [
            {
                id: 171,
                label: 'MENUITEMS.SETTING.LIST.ACCOUNTSETTING',
                icon: 'fa-user-cog',
                link: '/settings/account',
                badge: 'Settings',
                v: 'AccountSettings',
                parentId: 17
            },
            {
                id: 172,
                label: 'MENUITEMS.SETTING.LIST.BRANCHSETTING',
                icon: 'fa-code-branch',
                link: '/settings/branchsetting',
                badge: 'Settings',
                v: 'Branches',
                parentId: 17
            }, {
                id: 173,
                label: 'MENUITEMS.SETTING.LIST.LOCATIONSETTING',
                icon: 'fa-map-marker',
                link: '/settings/locationsetting',
                badge: 'Settings',
                v: 'LocationSettings',
                parentId: 17
            }
        ]
    }
];

