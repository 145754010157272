<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                {{year}} © OneWallet.
            </div>
            <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block">
                    Power by <a href="https://onetap.et/" target="_blank" class="text-reset">OneTap</a>
                </div>
            </div>
        </div>
    </div>
</footer>